// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acerca-de-mi-js": () => import("./../../../src/pages/acerca-de-mi.js" /* webpackChunkName: "component---src-pages-acerca-de-mi-js" */),
  "component---src-pages-articulos-js": () => import("./../../../src/pages/articulos.js" /* webpackChunkName: "component---src-pages-articulos-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-directos-js": () => import("./../../../src/pages/directos.js" /* webpackChunkName: "component---src-pages-directos-js" */),
  "component---src-pages-embed-twitch-js": () => import("./../../../src/pages/embed/twitch.js" /* webpackChunkName: "component---src-pages-embed-twitch-js" */),
  "component---src-pages-en-about-me-js": () => import("./../../../src/pages/en/about-me.js" /* webpackChunkName: "component---src-pages-en-about-me-js" */),
  "component---src-pages-en-embed-twitch-js": () => import("./../../../src/pages/en/embed/twitch.js" /* webpackChunkName: "component---src-pages-en-embed-twitch-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-projects-js": () => import("./../../../src/pages/en/projects.js" /* webpackChunkName: "component---src-pages-en-projects-js" */),
  "component---src-pages-en-skills-js": () => import("./../../../src/pages/en/skills.js" /* webpackChunkName: "component---src-pages-en-skills-js" */),
  "component---src-pages-en-streamer-js": () => import("./../../../src/pages/en/streamer.js" /* webpackChunkName: "component---src-pages-en-streamer-js" */),
  "component---src-pages-estudios-y-empresas-js": () => import("./../../../src/pages/estudios-y-empresas.js" /* webpackChunkName: "component---src-pages-estudios-y-empresas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-proyectos-js": () => import("./../../../src/pages/proyectos.js" /* webpackChunkName: "component---src-pages-proyectos-js" */),
  "component---src-pages-tecnologias-js": () => import("./../../../src/pages/tecnologias.js" /* webpackChunkName: "component---src-pages-tecnologias-js" */),
  "component---src-templates-portfolio-js": () => import("./../../../src/templates/portfolio.js" /* webpackChunkName: "component---src-templates-portfolio-js" */)
}

